import { apiCourserPc } from '@/api/api.js'
export default {
    components: {
    },
    data() {
        return {
            resultData: [],
            page: 1,
            pageNum: 5,
            loading: false,
            noMore: false
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.getSkillData()
        },
        getSkillData() {
            if (this.noMore == false) {
                // this.loading = true
                let paramsBase = {
                    page: this.page,
                    pageNum: this.pageNum,
                    lang: this.$i18n.locale
                }
                apiCourserPc(paramsBase).then((res) => {
                    let dataValue = res.data.list
                    if (dataValue.length != 0) {
                        setTimeout(() => {
                            dataValue.forEach(element => {
                                this.resultData.push(element)
                            });
                        }, 500);

                    } else {
                        this.loading = false
                        this.noMore = true
                    }
                })
            }
        },
        load() {
            this.page++
            this.getSkillData()
        },
        clickSeeDetails(item) {
            sessionStorage.setItem('tcl_course_id', item.id)
            this.$router.push({ name: 'SkillDetails' })
        }
        // sessionStorage.setItem('tcl_Ask_id', item.id)
    },
}